import React from 'react'
import styled from 'styled-components'
import { Wrapper0ArticleSearch } from '.'
import InputSearch from '../InputSearch'
import SkeletonSmallLandscapeItemArticle from '../../../Article/components/ItemArticle/views_default/SmallLandscapeItemArticle/skeleton'
import Breadcrumb from '../../../../components/Breadcrumb'
import translate from '../../../../modules/translate'
const ArticleWrapper = styled.div`
  width: 70%;
  margin-left: auto;
  margin-right: auto;
`
const WrapperSkeletonSmallLandscapeItemArticle = styled.div`
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
  &:first-child {
    margin-top: 0;
  }
`

const SkeletonArticleSearch = ({ sortBy, searchTerm, ...props }) => {
  return (
    <div>
      <Breadcrumb
        bookmark={{ show: false, state: false }}
        crumbs={[
          {
            name: translate('search_breadcrumb'),
            route: '#',
          },
        ]}
      />
      {sortBy && <InputSearch searchTerm={searchTerm} sortBy={sortBy}></InputSearch>}
      <ArticleWrapper>
        <WrapperSkeletonSmallLandscapeItemArticle>
          <SkeletonSmallLandscapeItemArticle />
        </WrapperSkeletonSmallLandscapeItemArticle>
        <WrapperSkeletonSmallLandscapeItemArticle>
          <SkeletonSmallLandscapeItemArticle />
        </WrapperSkeletonSmallLandscapeItemArticle>
        <WrapperSkeletonSmallLandscapeItemArticle>
          <SkeletonSmallLandscapeItemArticle />
        </WrapperSkeletonSmallLandscapeItemArticle>
        <WrapperSkeletonSmallLandscapeItemArticle>
          <SkeletonSmallLandscapeItemArticle />
        </WrapperSkeletonSmallLandscapeItemArticle>
        <WrapperSkeletonSmallLandscapeItemArticle>
          <SkeletonSmallLandscapeItemArticle />
        </WrapperSkeletonSmallLandscapeItemArticle>
        <WrapperSkeletonSmallLandscapeItemArticle>
          <SkeletonSmallLandscapeItemArticle />
        </WrapperSkeletonSmallLandscapeItemArticle>
      </ArticleWrapper>
    </div>
  )
}

export default SkeletonArticleSearch
