import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Block from '../../../../../../layouts/Blocks'

const Wrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`
const SizeDiv = styled.div`
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: 80%;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: 10px;
    width: 100%;
  }
`

const AllCategories = ({ data, showDate, ...props }) => {
  return (
    <Wrapper {...props}>
      {data &&
        data.map((entry) => {
          return (
            <SizeDiv>
              {entry.type == 'article' && (
                <Block view="landscape_article" data={{ articles: [entry.data], hideDefaults: !showDate }} />
              )}
              {entry.type == 'page' && !(entry.data.title.localeCompare('Lawinenbericht') == 0) && (
                <Block view="page_article" data={entry.data} />
              )}
            </SizeDiv>
          )
        })}
    </Wrapper>
  )
}
AllCategories.propTypes = {
  showDate: PropTypes.bool,
}
AllCategories.defaultProps = {}
export default AllCategories
