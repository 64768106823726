import React from 'react'
import styled from 'styled-components'
import ErrorBoundary from '../../../Error/widgets/ErrorBoundary'
import MessageError from '../../../Error/screens/MessageError'
import SearchResult from '../../components/SearchResult'
import SkeletonArticleSearch from '../../components/SearchResult/skeleton'
import translate from '../../../../modules/translate'
import searchSettings from '../../modules/search-settings'

const Wrapper = styled.div``

const errorOptions = {
  component: translate('search_error'),
  link: '/',
  linkText: translate('error_try_homepage'),
  logError: 'Search screen display',
}
const SearchScreen = ({ response: { data }, error, loading, ready, match, ...props }) => {
  const { searchTerm, sortBy } = searchSettings({ match })
  return (
    <ErrorBoundary {...errorOptions} {...props}>
      {error && <MessageError logInfo={error.message} {...errorOptions} />}
      {loading && <SkeletonArticleSearch searchTerm={searchTerm} sortBy={sortBy} />}
      {data && <SearchResult data={data} searchTerm={searchTerm} sortBy={sortBy} />}
    </ErrorBoundary>
  )
}
SearchScreen.propTypes = {}
SearchScreen.defaultProps = {
  response: {
    data: {},
  },
}
export default SearchScreen
