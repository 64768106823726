import React, { Component } from 'react'
import styled from 'styled-components'
import Input from '../../../../components/Input'
import Icon from '../../../../components/Icon'
import Text from '../../../../components/Text'
import translate from '../../../../modules/translate'
import Switch from '@mui/material/Switch'

const Wrapper = styled.div`
  margin: 0 auto;
  margin-top: 30px;
  width: ${(props) => props.theme.sizes.col8};
  position: relative;
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: 1080px) {
    margin-top: 0;
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
    width: calc(100% - 100px);
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
    width: calc(100% - 60px);
  }
`

const StyledInput = styled(Input)`
  width: 100%;
  padding: 10px 10px 10px 10px;
  box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.16);
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
  @media (max-width: 1080px) {
    padding: unset;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`

const StyledSearchIcon = styled(Icon)`
  font-size: 36px;
  color: ${(props) => props.theme.color.textTertiary};
  margin-right: 5px;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.color.backgroundBorder};
    transtion: all 0.5s ease;
  }
`

const StyledCloseIcon = styled(Icon)`
  font-size: 18px;
  color: ${(props) => props.theme.color.textTertiary};
  padding-right: 5px;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.color.stol};
    transtion: all 0.2 ease;
  }
`

const SwitchWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${(props) => props.theme.sizes.gapVerticalHalf};
  @media (max-width: 1080px) {
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
  }
`

const StyledSwitch = styled(Switch)`
  & .switchBase {
    color: #b71c1c;
  }
`

const StyledTitleSwitch = styled(Text)`
  margin: 0 auto;
  text-align: center;
  margin-top: ${(props) => props.theme.sizes.gapVerticalHalf};
  @media (max-width: 1080px) {
    margin-top: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
  }
  @media (max-width: 1080px) {
    margin-top: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
  }
`

const StyledText = styled(Text)`
  color: ${(props) => (props.selected ? props.theme.color.stol : props.theme.color.stol.textPrimary)};
  cursor: pointer;
`

class InputSearch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: this.props.searchTerm,
      sortBy: this.props.sortBy,
      submitted: false,
    }
    this.handleOrderbyChange = this.handleOrderbyChange.bind(this)
    this.handleSearchTermChange = this.handleSearchTermChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleOrderbyChange() {
    if (this.state.sortBy === 'relevance') {
      this.setState({
        sortBy: 'date',
      })
      this.props.handleOrderbyChange('date', this.props.searchTerm)
    } else {
      this.setState({
        sortBy: 'relevance',
      })
      this.props.handleOrderbyChange('relevance', this.props.searchTerm)
    } //change this to switch if more values will be added
  }
  handleSearchTermChange(event) {
    this.setState({
      searchTerm: event.target.value,
    })
  }
  handleSubmit() {
    this.props.handleSearchTermChange(this.state.searchTerm, this.state.sortBy)
  }
  render() {
    const { ...props } = this.props
    return (
      <Wrapper {...props}>
        <StyledInput
          prepend={<StyledSearchIcon onClick={this.handleSubmit} icon="search" />}
          onChange={this.handleSearchTermChange}
          value={decodeURIComponent(this.state.searchTerm)}
          append={<StyledCloseIcon onClick={() => this.setState({ searchTerm: '' })} icon="close" />}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              this.props.handleSearchTermChange(this.state.searchTerm, this.state.sortBy)
            }
          }}
        />
        <StyledTitleSwitch>{translate('sort_by')}:</StyledTitleSwitch>
        <SwitchWrapper>
          <StyledText onClick={this.handleOrderbyChange} selected={this.state.sortBy === 'relevance'}>
            {translate('search_switch_left')}
          </StyledText>
          <StyledSwitch
            checked={this.state.sortBy === 'relevance' ? false : true}
            color="default"
            onChange={this.handleOrderbyChange}
            classes={{ switchBase: 'switchBase' }}
          />
          <StyledText
            selected={this.state.sortBy === 'date'}
            onClick={this.handleOrderbyChange}
            checked={this.state.sortBy === 'date'}
          >
            {translate('search_switch_right')}
          </StyledText>
        </SwitchWrapper>
      </Wrapper>
    )
  }
}

InputSearch.defaultProps = {
  searchTerm: '',
  sortBy: 'relevance',
}

export default InputSearch
