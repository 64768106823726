import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import AllCategories from './components/AllCategories'
import InputSearch from '../InputSearch'
import translate from '../../../../modules/translate'
import Text from '../../../../components/Text'
import { htmlParser } from '../../../../modules/parse'
import Breadcrumb from '../../../../components/Breadcrumb'
import LoadMore from '../../../../components/LoadMore'
import isServerSide from '../../../../modules/is-server-side'

const StyledText = styled(Text)`
  margin: 0 auto;
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  margin-bottom: 30vh;
  font-size: ${(props) => props.theme.fontSize.title2};
  margin-left: ${(props) => props.theme.sizes.gapEdge};
  margin-right: ${(props) => props.theme.sizes.gapEdge};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.title2};
    margin-left: ${(props) => props.theme.tablet.sizes.gapEdge};
    margin-right: ${(props) => props.theme.tablet.sizes.gapEdge};
    margin-top: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-left: ${(props) => props.theme.mobile.sizes.gapEdge};
    margin-right: ${(props) => props.theme.mobile.sizes.gapEdge};
    font-size: ${(props) => props.theme.mobile.fontSize.title2};
    margin-top: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
  text-align: center;
`

const Wrapper = styled.div``

function handleSearchTermChange(newSearchTerm, sortBy) {
  let newSearchTermCheck = newSearchTerm.length > 0 ? newSearchTerm : 'Bozen'
  let sortByPush = sortBy && sortBy === 'relevance' ? 'relevanz' : sortBy === 'date' && 'datum'
  if (!isServerSide()) {
    window.location.href = '/suche/' + sortByPush + '/' + newSearchTermCheck
  }
}
function handleOrderbyChange(newOrderByValue, searchTerm) {
  let sortByPush =
    newOrderByValue && newOrderByValue === 'relevance' ? 'relevanz' : newOrderByValue === 'date' && 'datum'

  if (!isServerSide()) {
    window.location.href = '/suche/' + sortByPush + '/' + searchTerm
  }
}
const SearchResult = ({ data, searchTerm, sortBy, ...props }) => {
  const [helperArticles, setArticles] = useState(data)

  return (
    <Wrapper {...props}>
      <Breadcrumb
        bookmark={{ show: false, state: false }}
        crumbs={[
          {
            name: translate('search_breadcrumb'),
            route: '/suche',
          },
        ]}
      />
      <InputSearch
        searchTerm={searchTerm}
        handleSearchTermChange={handleSearchTermChange}
        handleOrderbyChange={handleOrderbyChange}
        sortBy={sortBy}
      />
      {helperArticles !== undefined &&
        helperArticles.searchEntries !== undefined &&
        helperArticles.searchEntries.length !== 0 && (
          <div>
            <AllCategories data={helperArticles.searchEntries} showDate={true} />
            <LoadMore
              loadMoreRoute={'/search/' + sortBy + '/' + searchTerm}
              loadMoreArgument={helperArticles.searchEntries.length}
              version={'v2'}
              articles={helperArticles.searchEntries}
              afterLoadMore={(newArticles) => {
                setArticles({ searchEntries: newArticles })
              }}
            />
          </div>
        )}
      {(helperArticles.searchEntries === undefined && searchTerm !== undefined) ||
        (helperArticles.searchEntries !== undefined &&
          helperArticles.searchEntries.length === 0 &&
          searchTerm !== undefined && (
            <StyledText>
              {htmlParser(translate('search_no_searchentries', { searchTerm: decodeURI(searchTerm) }))}
            </StyledText>
          ))}
    </Wrapper>
  )
}
SearchResult.propTypes = {}
SearchResult.defaultProps = {}
export default SearchResult
